<template>
  <v-container fluid>
    <v-row v-if="!loaderVisible" class="height-container" align-content="center">
      <v-col cols="12" class="d-flex justify-center">
        <v-img src="@/assets/images/weChamber/logo-WC.png" max-width="120" height="auto"></v-img>
      </v-col>
      <v-col cols="12">
        <p class="headline font-weight-bold text-center mb-0" :class="!isValidated?'red--text':''">{{isValidated?'¡La cuenta se ha validado correctamente!':'¡Hubo un problema al validar la cuenta del usuario!'}}</p>
      </v-col>
      <v-col cols="12" class="py-0">
        <p class="subtitle-1 text-center">{{isValidated?'Tu cuenta ha sido validada con éxito, ahora ya puedes comenzar a gestionar tus cursos y membresías.':'Verifica que la url ingresada sea correcta o que la validación del usuario no se haya realizado anteriormente.'}}</p>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn color="primary" class="font-weight-bold" rounded to="/login">Iniciar sesión</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapState, mapMutations, mapActions} from 'vuex';

export default {
  computed: {
    ...mapState("loader", ["loaderVisible"]),
  },
  data() {
    return {
      isValidated: false
    }
  },
  methods: {
    ...mapActions('authentication', ['validateNewUser']),
    ...mapMutations('notification', ['show']),
    ...mapMutations("loader", ["loading", "loaded"]),
  },
  async mounted() {
    this.loading();
    let response = await this.validateNewUser(this.$route.params.token);
    this.isValidated = response.status;
    this.loaded();
    this.show({
      text: response.message,
      color: response.status?"primary":"red"
    });
  }
}
</script>
<style scoped>
.height-container {
  height: 70vh;
}
</style>